import React from "react";
import theme from "theme";
import { Theme, Image, Text, Icon, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { BsDot } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ev | Ziklo Smart ile Evinize Hoş Geldiniz
			</title>
			<meta name={"description"} content={"Mükemmel alanınızı bizimle keşfedin"} />
			<meta property={"og:title"} content={"Ev | Ziklo Smart ile Evinize Hoş Geldiniz"} />
			<meta property={"og:description"} content={"Mükemmel alanınızı bizimle keşfedin"} />
			<meta property={"og:image"} content={"https://ziklosmart.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ziklosmart.com/img/6466149.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ziklosmart.com/img/6466149.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ziklosmart.com/img/6466149.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ziklosmart.com/img/6466149.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ziklosmart.com/img/6466149.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ziklosmart.com/img/6466149.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-13">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Image
					src="https://ziklosmart.com/img/1.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-end"
					width="60%"
					padding="80px 80px 80px 80px"
					lg-width="100%"
					md-padding="50px 50px 80px 50px"
					sm-padding="50px 25px 80px 25px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						align-items="center"
						margin="0px 0px 25px 0px"
						sm-flex-direction="column"
						sm-align-items="flex-start"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 16px/1.5 --fontFamily-sans" color="#061e11" letter-spacing="1px">
							Ziklo Smart ile Eviniz Sizi Bekliyor
						</Text>
					</Box>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 72px/1.2 --fontFamily-serifGeorgia"
						color="#061e11"
						lg-margin="0px 0px 35px 0px"
						md-font="normal 500 52px/1.2 --fontFamily-serifGeorgia"
						sm-font="normal 500 42px/1.2 --fontFamily-serifGeorgia"
					>
						Ziklo Smart
					</Text>
					<Text margin="0px 0px 40px 0px" font="normal 300 20px/1.3 --fontFamily-sans" color="#061e11" letter-spacing="1px">
						Ziklo Smart'ta, bir evin dört duvar ve bir çatıdan daha fazlası olduğunu biliyoruz. Hikayenizin ortaya çıktığı, anılarınızın oluştuğu ve dışarıdaki dünyanın koşuşturmacasından kaçıp sığındığınız yerdir. Sizin için mükemmel evi yaratmak üzere konfor, stil ve rahatlığın bir araya geldiği bir alana adım atın.
					</Text>
					<Link href="/contacts" color="--darkL1" font="normal 600 28px/1.2 --fontFamily-serifGeorgia">
						Bize Ulaşın &gt;&gt;
					</Link>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="37%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Hakkımızda
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="63%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Kendinizi Ziklo Smart farkına bırakın - sıcaklık, uzmanlık ve sarsılmaz bağlılığın bir karışımı. Gayrimenkul seçeneklerinin geniş manzarasında gezinirken, size verdiğimiz söz değişmez: şeffaflık, dürüstlük ve yeni evinizde geliştiğinizi görmek için gerçek bir arzu. Biz sadece işlemleri kolaylaştırmak için burada değiliz - güven ve karşılıklı saygıya dayanan kalıcı ilişkiler kurmak için buradayız. Arzularınızın uçuşa geçtiği ve eve dönüş yolculuğunuzun bizimle başladığı bir yere hoş geldiniz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
							Neden Ziklo Smart'ı Seçmelisiniz?
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Kişiselleştirilmiş Hizmet
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Özel ekibimiz, gayrimenkul yolculuğunuzun her adımında size rehberlik etmek, ihtiyaçlarınızın ve tercihlerinizin hassasiyet ve özenle karşılanmasını sağlamak için burada.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									En İyi Konumlar
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Aranılan mahallelerde bulunan ve olanaklara, okullara ve ulaşıma kolay erişim sunan çeşitli mülk portföyümüzü keşfedin.

							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Şeffaf İşlemler
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Güven, işimizin temelidir. Açık iletişime ve dürüstlüğe inanıyoruz, böylece satın alma, satma veya kiralama süreci boyunca kendinizi güvende ve bilgili hissedebilirsiniz.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Uzmanlık ve Deneyim
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Gayrimenkul sektöründe yıllara dayanan deneyimimizle, bilinçli kararlar vermenize ve hedeflerinize ulaşmanıza yardımcı olacak bilgi ve içgörüye sahibiz.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});